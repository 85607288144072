<template>
  <div v-if="section" class="row">
    <div class="col-md-6">
      <ColorInput v-model="section.BackgroundColor" label="Background" />
    </div>
    <div class="col-md-6">
      <ColorInput v-model="section.BorderColor" label="Border color" />
    </div>
    <div class="col-md-6">
      <ColorInput v-model="section.Color" label="Color" />
    </div>
    <div class="col-md-6">
      <ColorInput v-model="section.LabelColor" label="Label Color" />
    </div>
    <div class="col-md-6">
      <NumberInput v-model="section.Roudness" label="Roudness" />
    </div>
    <div class="col-md-6">
      <NumberInput v-model="section.FontSize" label="Font size" />
    </div>
    <div class="col-md-6"></div>
    <div class="col-md-12">
      <div class="d-flex justify-content-between mb-3">
        <label class="mr-4">Show Name Input</label>
        <b-form-checkbox
          v-model="section.ShowName"
          switch
        ></b-form-checkbox>
      </div>
    </div>
    <template v-if="section.ShowName">
    <div class="col-md-12">
      <div class="form-group">
        <label>Name placeholder</label>
        <input
          v-model="section.NamePlaceholder"
          type="text"
          class="form-control"
          placeholder="Your name"
        />
      </div>
    </div>
    <div class="col-md-12">
      <div class="d-flex justify-content-between mb-3 landing-input-checkbox">
        <b-form-checkbox v-model="section.NameRequired" name="nameRequired">
          Required
        </b-form-checkbox>
      </div>
    </div>
    </template>
    <div class="col-md-12">
      <div class="d-flex justify-content-between mb-3">
        <label class="mr-4">Show Birthday Input</label>
        <b-form-checkbox
          v-model="section.ShowBirthday"
          switch
        ></b-form-checkbox>
      </div>
    </div>
    <template v-if="section.ShowBirthday">
    <div class="col-md-12">
      <div class="form-group">
        <label>Birthday label</label>
        <input
          v-model="section.BirthdayLabel"
          type="text"
          class="form-control"
        />
      </div>
    </div>
    <div class="col-md-12">
      <div class="d-flex justify-content-between mb-3 landing-input-checkbox">
        <b-form-checkbox v-model="section.BirthdayRequired" name="birthdayRequired">
          Required
        </b-form-checkbox>
      </div>
    </div>
    </template>
    <div class="col-md-12">
      <div class="d-flex justify-content-between mb-3">
        <label class="mr-4">Show Anniversary Input</label>
        <b-form-checkbox
          v-model="section.ShowAnniversary"
          switch
        ></b-form-checkbox>
      </div>
    </div>
    <template v-if="section.ShowAnniversary">
    <div class="col-md-12">
      <div class="form-group">
        <label>Anniversary label</label>
        <input
          v-model="section.AnniversaryLabel"
          type="text"
          class="form-control"
        />
      </div>
    </div>
    <div class="col-md-12">
      <div class="d-flex justify-content-between mb-3 landing-input-checkbox">
        <b-form-checkbox v-model="section.AnniversaryRequired" name="anniversaryRequired">
          Required
        </b-form-checkbox>
      </div>
    </div>
    </template>
    <div class="col-md-12">
      <div class="d-flex justify-content-between mb-3">
        <label class="mr-4">Show Business Name</label>
        <b-form-checkbox
          v-model="section.ShowBusinessName"
          switch
        ></b-form-checkbox>
      </div>
    </div>
    <template v-if="section.ShowBusinessName">
    <div class="col-md-12">
      <div class="form-group">
        <label>Business Name placeholder</label>
        <input
          v-model="section.BusinessNamePlaceholder"
          type="text"
          class="form-control"
          placeholder="Business Name"
        />
      </div>
    </div>
    <div class="col-md-12">
      <div class="d-flex justify-content-between mb-3 landing-input-checkbox">
        <b-form-checkbox v-model="section.BusinessNameRequired" name="businessNameRequired">
          Required
        </b-form-checkbox>
      </div>
    </div>
    </template>
    <div class="col-md-12">
      <div class="d-flex justify-content-between mb-3">
        <label class="mr-4">Show Website URL</label>
        <b-form-checkbox
          v-model="section.ShowWebsiteUrl"
          switch
        ></b-form-checkbox>
      </div>
    </div>
    <template v-if="section.ShowWebsiteUrl">
    <div class="col-md-12">
      <div class="form-group">
        <label>Website URL placeholder</label>
        <input
          v-model="section.WebsiteUrlPlaceholder"
          type="text"
          class="form-control"
          placeholder="Website URL"
        />
      </div>
    </div>
    <div class="col-md-12">
      <div class="d-flex justify-content-between mb-3 landing-input-checkbox">
        <b-form-checkbox v-model="section.WebsiteUrlRequired" name="websiteUrlRequired">
          Required
        </b-form-checkbox>
      </div>
    </div>
    </template>
    <div class="col-md-12 mb-3 text-center">
      <a href="javascript:;" class="btn btn-primary btn-sm" @click="onManageCustomFieldClick"><i class="uil uil-code"></i> Manage Custom Fields</a>
    </div>
    <div v-for="(item, index) in sectionCustomFields" :key="index" class="col-md-12">
      <div class="d-flex justify-content-between mb-3">
        <label class="mr-4">Show {{ item.Name }} Input</label>
        <b-form-checkbox
          v-model="item.ShowField"
          switch
        ></b-form-checkbox>
      </div>
      <template v-if="item.ShowField">
        <div class="d-block">
          <div class="form-group">
            <label>{{ getCustomFieldLabel(item) }}</label>
            <input
              v-model="item.Placeholder"
              type="text"
              class="form-control"
              placeholder="Your Email"
            />
          </div>
        </div>
        <div class="d-block">
          <div class="d-flex justify-content-between mb-3 landing-input-checkbox">
            <b-form-checkbox v-model="item.Required" :name="`${item.name}Required`">
              Required
            </b-form-checkbox>
          </div>
        </div>
      </template>
    </div>
    <div class="col-md-12">
      <div class="d-flex justify-content-between mb-3">
        <label class="mr-4">Show Email Input</label>
        <b-form-checkbox
          v-model="section.ShowEmail"
          switch
        ></b-form-checkbox>
      </div>
    </div>
    <template v-if="section.ShowEmail">
    <div class="col-md-12">
      <div class="form-group">
        <label>Email placeholder</label>
        <input
          v-model="section.EmailPlaceholder"
          type="text"
          class="form-control"
          placeholder="Your Email"
        />
      </div>
    </div>
    <div class="col-md-12">
      <div class="d-flex justify-content-between mb-3 landing-input-checkbox">
        <b-form-checkbox v-model="section.EmailRequired" name="emailRequired">
          Required
        </b-form-checkbox>
      </div>
    </div>
    </template>
    <div class="col-md-12">
      <div class="d-flex justify-content-between mb-3">
        <label class="mr-4">Show Phone Input</label>
        <b-form-checkbox
          v-model="section.ShowPhone"
          switch
        ></b-form-checkbox>
      </div>
    </div>
    <template v-if="section.ShowPhone">
    <div class="col-md-12">
      <div class="form-group">
        <label>Phone placeholder</label>
        <input
          v-model="section.PhonePlaceholder"
          type="text"
          class="form-control"
          placeholder="Your phone"
        />
      </div>
    </div>
    <div class="col-md-12">
      <div class="d-flex justify-content-between mb-3 landing-input-checkbox">
        <b-form-checkbox v-model="section.PhoneRequired" name="phoneRequired">
          Required
        </b-form-checkbox>
      </div>
    </div>
    </template>
    <b-modal v-model="showCustomFieldModal" no-close-on-backdrop>
      <template v-slot:modal-title>
        <h5 class="text-primary">Custom Fields</h5>
      </template>
      <div class="row new-custom-fields-modal">
        <div v-for="(item, index) in customFields" :key="index" class="col-md-12 d-flex justify-content-between mb-3 landing-input-checkbox">
          <b-form-checkbox v-model="newFieldsList[item.id]" :name="item.name">
            {{ item.name }}
          </b-form-checkbox>
        </div>
      </div>
      <template v-slot:modal-footer>
        <b-button variant="light" @click="showCustomFieldModal = false">Cancel</b-button>
        <b-button variant="primary" @click="updateCustomField">Update</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import NumberInput from './NumberInput'
import ColorInput from './ColorInput'

export default {
  components: {
    NumberInput,
    ColorInput,
  },

  props: {
    section: {
      type: [Object],
      default: null,
    },
  },

  data() {
    return {
      showCustomFieldModal: false,
      newFieldsList: {},
    }
  },

  computed: {
    user() {
      return this.$store.getters['auth/user']
    },
    
    customFields() {
      return this.$store.getters["auth/customFields"];
    },

    sectionCustomFields() {
      if (!this.section.CustomFields) {
        // eslint-disable-next-line
        this.section.CustomFields = []
      }
      const customFieldIds = this.customFields.flatMap(item => item.id)
      return this.section.CustomFields.filter(item => customFieldIds.includes(item.Id))
        .sort((a, b) => a.id < b.id ? 1 : -1 )
    }
  },

  mounted() {
    this.sectionCustomFields.forEach(item => {
      this.newFieldsList[item.Id] = true
    })
  },

  methods: {
    getCustomFieldLabel(item) {
      if (item.Type === 2)
        return `${item.Name} Label`
      return `${item.Name} Placeholder`
    },
    onManageCustomFieldClick() {
      this.showCustomFieldModal = true
    },
    updateCustomField() {
      this.customFields.forEach(item => {
        const itemIndex = this.section.CustomFields.findIndex(tmp => tmp.Id === item.id)
        if (this.newFieldsList[item.id]) {
          if (itemIndex === -1) {
            this.section.CustomFields.push({
              Id: item.id,
              Type: item.type,
              Name: item.name,
              Placeholder: item.name,
              ShowField: true,
              Required: true,
            })
          }
        } else {
          if (itemIndex !== -1) {
            this.section.CustomFields.splice(itemIndex, 1);
          }
        }
      });
      this.showCustomFieldModal = false
    },
  }
}
</script>

<style lang="scss">
.landing-input-checkbox {
  .custom-checkbox {
    padding-left: 30px;
    margin-bottom: 5px;
  }

  .custom-control-label:before, .custom-control-label:after {
    width: 20px;
    height: 20px;
    left: -30px;
    top: 0px;
  }

  .custom-control-input {
    width: 20px;
    height: 20px;
    top: -4px;
  }
}
</style>